import {CircularProgress, Grid} from "@mui/material";

export default () => {
    return (
        <div style={{display: "flex", alignItems: "center", minHeight: "70vh"}}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                <Grid item xs={3}>
                    <CircularProgress size={100} style={{color: "#005f6f"}}/>
                    <p>Loading ...</p>
                </Grid>
            </Grid>
        </div>
    );
}
