import './App.css';
import Menu from "./components/Menu/Menu";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {withPassRouteMatches} from "./utils/utils"
import GenePage from "./components/Pages/GenePage";
import Frontpage from "./components/Pages/Frontpage";
import About from "./components/Pages/About";
import Terms from "./components/Pages/Terms";
import VariantPage from "./components/Pages/VariantPage";
import RegionPage from "./components/Pages/RegionPage";
import { LicenseManager } from  'ag-grid-enterprise'
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

LicenseManager.setLicenseKey(process.env.REACT_APP_LICENSE_KEY as string)
function App() {
    const RoutedFrontpage = withPassRouteMatches(Frontpage);
    const RoutedGenePage = withPassRouteMatches(GenePage);
    const RoutedVariant = withPassRouteMatches(VariantPage);
    const RoutedRegion = withPassRouteMatches(RegionPage);
    const RoutedAbout = withPassRouteMatches(About);
    const RoutedTerms = withPassRouteMatches(Terms);

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
        ReactGA.send(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter>
            <div className="App">
                <Menu/>
                <br/>
                <Routes>
                    <Route path="/" element={<RoutedFrontpage/>}/>
                    <Route path="/gene/:geneId" element={<RoutedGenePage/>}/>
                    <Route path="/variant/:varId" element={<RoutedVariant/>}/>
                    <Route path="/region/:region" element={<RoutedRegion/>}/>
                    <Route path="/about" element={<RoutedAbout/>}/>
                    <Route path="/terms" element={<RoutedTerms/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}
export default App;
