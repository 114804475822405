import {useEffect} from 'react'
import Grid from '@mui/material/Grid';


const Terms = () => {
    useEffect(() => {
        document.title = "Biobank AFB - Terms";
    }, []);
    return (
        <Grid container spacing={2} style={{textAlign: "left"}}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
                <h2 style={{textAlign: "center"}}>Terms of Use</h2>
                <p>The UK Biobank Allele Frequency Browser (the “Browser”) is a site operated by UK Biobank Limited. Please refer to the UK Biobank <a href="https://www.ukbiobank.ac.uk/media/t2paae10/biobank-website-terms-of-use-final-18-11-20.pdf">Website Terms of Use</a> and terms below which covers the use of the UK Biobank Allele Frequency Browser (together the “AFB Terms”). By accessing and using the Browser you hereby agree to the AFB Terms.</p>
                <p>The Browser is a free-to-access resource of variant allele frequencies derived from summary statistics generated from research that has been conducted by the UK Biobank Whole Genome Sequencing Consortium using the UK Biobank Resource. Use of the Browser is for the biomedical research community and the purposes of conducting health-related research purposes only.</p>
                <h3>Access to Browser</h3>
                <p>Whilst UK Biobank has a reasonable belief that the use of the Browser and the summary statistics should not require any further licence or permission, the Browser and the summary statistics are provided by UK Biobank on an "As-Is" basis, and no warranties or representations, expressed or implied, are given about the performance, accuracy, completeness, currency or that results which may be obtained from the use of the Browser will be error free or reliable.</p>
                <p>UK Biobank makes no assurance that access to the Browser will always be available or be uninterrupted. We reserve the right to withdraw or amend the service we provide on the Browser without notice. We will not be liable, if for any reason, the Browser is unavailable at any time or for any period.</p>
                <p>UK Biobank hereby excludes any and all liability to any third party arising from the use of the Browser and the summary statistics.</p>
                <h3>Copyright notice and citations</h3>
                <p>All trademarks, logos and brand names displayed on this website are the property of their respective owners and are subject to copyright and trademark laws. You must not copy or use these without the express permission of their respective owner.</p>
                <p>UK Biobank requests that any published use of material obtained from the Browser in publications cite that the data has been generated by the WGS consortium under the UK Biobank Resource (project ID 52293).</p>
            </Grid>
        </Grid>
    );
};
export default Terms;