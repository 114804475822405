import axios from "axios";
import {useParams} from "react-router-dom";

export async function fetchGraphQL(query, variables) {
    let res = await axios.post(
        "/api",
        {
            "query": query,
            "variables": variables
        }
    );
    return res.data.data;
}

export function withPassRouteMatches(Component) {
    const WrappedComponent = (props) => {
        const params = useParams();
        return (
            <Component
                {...props}
                {...params}
            />
        );
    }
    return WrappedComponent;
}

export function capitalise(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatScientific(num) {
    // Fails silently if num is undefined
    if (num === null || num === undefined) {
        return ''
    } else if (num >= 0.0001) {
        return Number(num.toFixed(5));
    } else {
        const truncated = Number(num.toPrecision(3))
        if (truncated === 0 || truncated === 1) {
            return num.toFixed(0)
        } else {
            return truncated.toExponential(2)
        }
    }
}

export function splitRegion(region) {
    let [chrom, begin, end] = region.split(/-|:/);
    return [chrom, parseInt(begin), parseInt(end)];
}

export function splitVariant(variant) {
    let [chrom, pos, ref, alt] = variant.split(/-|:/);
    return [chrom, parseInt(pos), ref, alt];
}

export default {
    fetchGraphQL: fetchGraphQL,
    withPassRouteMatches: withPassRouteMatches,
    capitalise: capitalise,
    formatScientific: formatScientific,
    splitRegion: splitRegion,
    splitVariant: splitVariant
}