import Grid from '@mui/material/Grid';
import {useEffect} from 'react';
import amgenlogo from "../images/Amgen.png";
import astrazenecalogo from "../images/AstraZeneca.png";
import gsklogo from "../images/GSK.png";
import uKRIlogo from "../images/UKRI.png";
import deCodelogo from "../images/decode-logo-UKBiobank.png";
import illuminalogo from "../images/illumina.png";
import awslogo from "../images/AWS.png";
import jjlogologo from "../images/JJ_Logo_Stacked_Red_RGB.png";
import wellcomesangerlogo from "../images/Wellcome Sanger.png";
import wellcomelogo from "../images/wellcome-logo-black.png";


const logoStyle = {
    width: 120,
    margin: "10px",
    maxWidth: "90vw",
};

const About = () => {
    useEffect(() => {
        document.title = "Biobank AFB - About";
    }, []);
    return (
        <Grid container spacing={2} style={{ textAlign: "left" }}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item xs={10} md={6}>
                <h2 style={{ textAlign: "center" }}>We thank the participants of UK Biobank</h2>

                <p>The world-leading project to whole genome sequence all 500,000 participants within UK Biobank has been funded by the UK Biobank WGS consortium consisting of UK Government’s research and innovation agency, UK Research and Innovation (UKRI), through the Industrial Strategy Challenge Fund, Wellcome and the pharmaceutical companies Amgen, AstraZeneca, GSK and Johnson & Johnson. The research project has been undertaken within the scope of Application ID 52293.</p>

                <p>Whole genome sequencing was performed at the Wellcome Sanger Institute and deCODE genetics.</p>

                <p>The number of carriers presented is the sum of the number of carriers in three sub-cohorts defined within the UK Biobank: British Irish, African and South-Asian. Individuals not included in one of these three sub-cohorts may be additional carriers.</p>
                
                <p>The chromosomal positions are expressed according to hg38 (Genome Reference Consortium Human Build 38). The variant ID is constructed with the following structure: Chromosome-Hg38position-Reference-Alternative.</p>
                
                <p>Further details are provided in the following pre-print article:</p>
                
                <>
                    <p>
                        <strong>Whole-genome sequencing of half-a-million UK Biobank participants</strong>
                        <br />
                        The UK Biobank Whole-Genome Sequencing Consortium, Shuwei Li, Keren J Carss, Bjarni V Halldorsson, Adrian Cortes
                        <br />
                        December 08, 2023
                        <br />
                        <a href='https://www.medrxiv.org/content/10.1101/2023.12.06.23299426v1' target='_blank'>https://www.medrxiv.org/content/10.1101/2023.12.06.23299426v1</a>
                    </p>
                </>
                <p>The UK Biobank Allele Frequency Brower is provided free of charge to the research community for research purposes only. It is not a medical device and may not be used for any diagnostic or medical purposes. UK Biobank is grateful to deCODE genetics who have contributed the underlying source code for hosting this Allele Frequency Browser.</p>
                
                <h3>Consortium</h3>

                <div className="logo-container">
                    <img src={amgenlogo} alt='amgenlogo' style={logoStyle} />
                    <img src={astrazenecalogo} alt='astrazenecalogo' style={logoStyle} />
                    <img src={awslogo} alt='awslogo' style={logoStyle} />
                    <img src={deCodelogo} alt='deCodelogo' style={{ height:'120px', margin: "10px"} } />
                    <img src={gsklogo} alt='gsklogo' style={logoStyle} />
                    <img src={illuminalogo} alt='illuminalogo' style={logoStyle} />
                    <img src={jjlogologo} alt='jjlogologo' style={logoStyle} />
                    <img src={uKRIlogo} alt='uKRIlogo' style={logoStyle} />
                    <img src={wellcomesangerlogo} alt='wellcomesangerlogo' style={logoStyle} />
                    <img src={wellcomelogo} alt='wellcomelogo' style={{ height:'80px', margin: "10px"} } />
                </div>
                <br/><br/>
            </Grid>
        </Grid>
    );
};
export default About;
