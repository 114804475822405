import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as PropTypes from "prop-types";

function InfoCardContent(props: { pageType: string; pageTypeValue: string; }) {
    return <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
            {props.pageType}
        </Typography>
        <Typography variant="h5" component="div">
            {props.pageTypeValue}
        </Typography>
    </CardContent>;
}

InfoCardContent.propTypes = {
    pageType: PropTypes.string,
    region: PropTypes.any
};


export default InfoCardContent;