import * as React from 'react';
import {SyntheticEvent, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {styled} from '@mui/material/styles';
import {Box, Popper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useFetchGraphQL} from "../../hooks/useFetchGraphQL";
import {splitRegion, splitVariant} from "../../utils/utils";

type Option = {
    type: string,
    optionLabel: string,
    optionBase: string
}

const StyledPopper = styled(Popper)(() => ({
    '& .MuiAutocomplete-groupLabel': {
        textAlign: "left"
    },
}));

const isRegionOrVarint = (userInput: string): boolean[] => {
    const reRegion = /(chr)?([1-9]|1[0-9]|2[0-2]|x|y|m|un)[-:]([1-9])/i
    const reVariant = /^(chr)?([1-9]|1[0-9]|2[0-2]|x|y|m|un)[-:]([1-9]\d*)(-([actgn\*]+)(-([actgn\*]))?)?/i
    const splitUserInput = userInput.split(/-|:/);
    let isRegion = userInput.toLowerCase().trim().search(reRegion) === 0;
    if (splitUserInput.length === 3) {isRegion = isRegion && (splitUserInput[2] === "" || Number.isInteger(parseFloat(splitUserInput[2])));}
    isRegion = isRegion && !(splitUserInput.length > 3);
    let isVariant = userInput.toLowerCase().trim().search(reVariant) === 0;
    return [isRegion, isVariant];
}

const constructRegionOptions = (regionStr: string): string[] => {
    let [chrom, begin, end] = splitRegion(regionStr);
    // Need to add chr if a user only gave the chromosome and vice versa.
    chrom = chrom.toLowerCase().startsWith("chr") ? chrom : "chr" + chrom;
    let regions = [];
    // if the user is specifying a valid region
    // we want to include the region at the top of the list
    if (end && end >= begin) {regions.push(`${chrom.toLowerCase()}-${begin}-${end}`)};
    if (!end) {
        regions.push(`${chrom.toLowerCase()}-${begin}-${begin}:Single Position`);
        regions.push(`${chrom.toLowerCase()}-${begin}-${begin + 10}:First 10 bases`);
        regions.push(`${chrom.toLowerCase()}-${begin}-${begin + 100}:First 100 bases`);
    }
    return regions;
}

// @ts-ignore
const DecafAutocomplete = (props) => {
    const {renderInput} = props;
    let navigate = useNavigate();
    const [inputValue, setInputValue] = React.useState("");
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [matchedValues, setMatchedValues] = React.useState<Option[]>([]);
    const navigateStuff = async (e: SyntheticEvent, option: Option) => {navigate(`${option.type.toLowerCase()}/${option.optionLabel}`)};
    const searchGeneQuery = `query genesSearch($name: String!) {
                     genes(searchStr: $name)
                 }`;
    const searchVariantQuery = `query variantSearch($searchStr: String!) {
                         variantNames(searchStr: $searchStr)
                     }`;

    const {fetchData: searchGeneData} = useFetchGraphQL(searchGeneQuery, {"name": inputValue}, false);
    const {fetchData: fetchVariantData} = useFetchGraphQL(searchVariantQuery, {"searchStr": inputValue}, false);
    useEffect(() => {
        const asyncWrap = async () => {
            const [isRegion, isVariant] = isRegionOrVarint(inputValue);
            let newMatchedValues: Option[] = [];
            const timeout = setTimeout(() => {
                setLoading(true);
                setMatchedValues([]);
            }, 100);

            if (inputValue.length > 0) {
                if (isVariant) {
                    const {data} = await fetchVariantData();
                    // No need to populate the dropdown if the legal variant string doesn't actually exist
                    if (data.variantNames) {
                        newMatchedValues = newMatchedValues.concat(
                            data.variantNames.map((s: string) => {
                                return {type: "Variant", optionLabel: s};
                            })
                        );
                    }
                }
                if (isRegion) {
                    const regions = constructRegionOptions(inputValue);
                    newMatchedValues = newMatchedValues.concat(
                        regions.map((s: string) => {
                            let [label, baseStr] = s.split(":");
                            return {type: "Region", optionLabel: label, optionBase: baseStr};
                        })
                    );
                } else if (inputValue.length > 0) {
                    const {data} = await searchGeneData();

                    newMatchedValues = newMatchedValues.concat(
                        data.genes.slice(0, 10).map((s: string) => {
                            return {type: "Gene", optionLabel: s}
                        })
                    )
                }
            }
            clearTimeout(timeout);
            setLoading(false);
            setMatchedValues(newMatchedValues);
        }
        asyncWrap().catch((e) => {
            console.error(e);
        })
    }, [inputValue])
    const handleInputChange = async (e: SyntheticEvent, newVal: string) => {
        if (!newVal) {newVal = "";}
        newVal = newVal.trim();
        const [isRegion, isVariant] = isRegionOrVarint(newVal);
        if (isVariant) {
            const [chrom, pos, ref, alt] = splitVariant(newVal);
            if (pos >= Number.MAX_SAFE_INTEGER) return;
        } else if (isRegion) {
            const [chrom, begin, end] = splitRegion(newVal);
            if (begin >= Number.MAX_SAFE_INTEGER || end >= Number.MAX_SAFE_INTEGER) return;
        }
        if (inputValue === newVal) return;
        setInputValue(newVal);
    }
    const EmptySearchText = (<div style={{textAlign: "left"}}>
        {inputValue &&
            <Typography sx={{marginBottom: 1}}>No matches</Typography>
        }
        <Typography>
            Example region search:
        </Typography>
        <Typography variant="subtitle1" sx={{marginLeft: 4}}>chr1-55040960-55040969</Typography>

        <Typography>
            Example variant search:
        </Typography>
        <Typography variant="subtitle1" sx={{marginLeft: 4}}>chr1-55040966-A-T</Typography>
    </div>)

    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Option, state: any) => {
        const newProps = {
            ...props,
            style: {
                ...props.style,
                borderBottom: '1px solid #ddd'
            },
            onMouseEnter: (event: React.MouseEvent<HTMLLIElement>) => {
                event.currentTarget.style.backgroundColor = "#e0e0e0";
            },
            onMouseLeave: (event: React.MouseEvent<HTMLLIElement>) => {
                event.currentTarget.style.backgroundColor = "";
            },
        };
        return (
            <li {...newProps} key={newProps.id}>
                <Box>
                    <Typography variant="body1">
                        {option.optionLabel}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="left">
                        {option.optionBase}                        
                    </Typography>
                </Box>
            </li>
        )
    }
    /* 
    The isOptionEqualToValue is pretty useless, it was only added to suppress a warning.
    The component might need a major overhaul to get rid of the warning.
    Feel free to log option.optionLabel and value.optionLabel while jumping between
    different regions if you want to see the warning in action and why it is occurring.
    */
    // @ts-ignore
    return (
        <>
            <Autocomplete
                sx={{textAlign: "left"}}
                disablePortal
                autoHighlight
                loading={isLoading}
                noOptionsText={EmptySearchText}
                groupBy={(d: Option) => d.type}
                filterOptions={(options, state) => options}
                options={matchedValues}
                // getOptionLabel, nasty workaround to clear search box
                getOptionLabel={() => ""}
                isOptionEqualToValue={(option, value) => {
                    return (typeof option.optionLabel === "string") === (typeof value.optionLabel === "string");
                }}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                // @ts-ignore
                onChange={navigateStuff}
                // @ts-ignore
                PopperComponent={StyledPopper}
                renderInput={renderInput}
                handleHomeEndKeys={false}
                renderOption={renderOption}
            />
        </>
    );
};

export default DecafAutocomplete;
