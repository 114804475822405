import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "@mui/material";
import DecafAutocomplete from '../Search/DecafAutocomplete';
import {useEffect} from 'react';

// @ts-ignore
import logo from "../images/ukb-blue-logo.png";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    outline: "5px solid #005f6f",
    width: '100%'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '60ch'
        },
    },
}));

const Frontpage = () => {
    useEffect(() => {
        document.title = "UK Biobank Allele Frequency Browser";
    }, []);
    // @ts-ignore
    return (
        <>
            <img src={logo} width={700} style={{ marginTop: 100, maxWidth: "90vw" }} />
            <div style={{ width: "500px", maxWidth: "90vw", margin: "30px auto" }}>
                <DecafAutocomplete
                    // @ts-ignore
                    renderInput={(params) => (
                        <Search ref={params.InputProps.ref}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                inputProps={{ ...params.inputProps }}
                                placeholder="Search for a gene, variant or region…"
                            />
                        </Search>
                    )}
                />
            </div>
            <br />
            <p style={{}}><strong>Examples: </strong></p>
            <Link style={{ textDecoration: "underline", cursor: "pointer" }}
                href="/gene/PCSK9">
                PCSK9
            </Link> <br />
            <Link style={{ textDecoration: "underline", cursor: "pointer" }}
                href="/variant/chr13-32398489-A-T">
                {"chr13-32398489-A-T"}
            </Link>
            <br />
            <Link style={{ textDecoration: "underline", cursor: "pointer" }}
                href="/region/chr1-55039479-55039500">
                chr1-55039479-55039500
            </Link>
            <div style={{marginTop: 60, paddingBottom: 60}}>
                <p>The UK Biobank Allele Frequency Browser is a resource of variant allele frequencies and is being made publicly available.</p>
                <p>The dataset encompasses SNP and indel variant calls in 490,640 individuals from whole genome sequencing of all UK Biobank participants.</p>
            </div>
            <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: "#005f6f", color: "white" }}>
                <p style={{display: "inline-block"}}>© UK Biobank Limited </p>
            </div>

        </>
    );
};
export default Frontpage;