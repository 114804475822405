import {useEffect, useState} from "react";
import axios from "axios";

export const useFetchGraphQL = (query: string, variables: any, autoUpdate: boolean = true) => {
    const [data, setData] = useState<any>(undefined);
    const [errors, setErrors] = useState<any>("");
    const [loading, setLoading] = useState<boolean>(true);
    const fetchData = async () => {
        setLoading(true);
        setErrors("");  // clear errors on new fetch
        setData(undefined);
        const res = await axios.post(
            "/api",
            {
                "query": query,
                "variables": variables,
            }
        );
        const data = res.data.data;
        const errors = res.data.errors;
        if (errors) {
            setErrors(errors);
        } else {
            setData(data);
        }
        setLoading(false)
        return {data, errors}
    };
    let deps: any[] = [];
    if (autoUpdate) {
        const varsComparable = JSON.stringify(variables);
        deps = [varsComparable]
    }
    useEffect( () => {
        if (autoUpdate)
            fetchData();
    }, deps)
    return {data, errors, loading, fetchData};
};
