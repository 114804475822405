import {useEffect} from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {Grid} from "@mui/material";
import PopulationTable from "../Variant/PopulationTable";
import VariantInfo from "../Variant/VariantInfo";
import Loading from "../Loading/Loading";
import {useFetchGraphQL} from "../../hooks/useFetchGraphQL";
import Typography from "@mui/material/Typography";

const query = `
query VariantSplitPopulationQuery($chrom: String!, $pos: Int!, $ref: String!, $alt: String!) {
    variant(chrom: $chrom, pos: $pos, ref: $ref, alt: $alt) {
      Chrom
      Pos
      Ref
      Alt
      alleleCount
      alleleNum
      alleleFreq
      nHomozygotes
      maxImpact
      maxConsequence
      HGVSp
      geneSymbol
      populationDetails {
        population
        alleleCount
        alleleNum
        alleleFreq
        nHomozygotes
        nHemiAlt
      }
    }
  } 
`

const VariantPage = (props) => {
    const {varId} = props;
    const [chrom, pos, ref, alt] = varId.split('-');
    const {data, errors, loading} = useFetchGraphQL(query, {"chrom": chrom, "pos": parseInt(pos), "ref": ref, "alt": alt});
    useEffect(() => {
        document.title = "Biobank AFB - " + varId;
    }, [varId])

    if (loading) {
        return (
            <Loading/>
        )
    } else if (errors) {
        return <>
            <Typography>Error fetching data</Typography>
            <Typography>Could not fetch data for variant: {varId}</Typography>
        </>

    } else {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '90vh'}}
            >
                <Grid item container
                      style={{width: "70vw"}}
                      direction="column"
                      alignItems="flex-start"
                >
                    <Grid item style={{width: "100%", textAlign: "left"}}>
                        <VariantInfo {...data.variant} />
                    </Grid>

                    <Grid item style={{width: "100%"}}>
                        <PopulationTable {...props} data={data.variant.populationDetails}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};


export default VariantPage;
