import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { formatScientific } from "../../utils/utils";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const VariantInfo = (props) => {
    const {
        Chrom, Pos, Ref, Alt, alleleCount, alleleNum, alleleFreq,
        nHomozygotes, maxImpact, maxConsequence, HGVSp, geneSymbol
    } = props;
    return (
        <Card>
            <CardContent>
                <div style={{ paddingLeft: 12 }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Variant
                    </Typography>
                    <Typography variant="h5" component="div" style={{ margin: 0, padding: 0 }}>
                        {`${Chrom}-${Pos}-${Ref}-${Alt}`}
                    </Typography>
                </div>
                <br />
                <Grid container spacing={2} >
                    <Grid item style={{minWidth:"400px", flexGrow: 0}}>
                        <TableContainer style={{ overflow: 'auto'}}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row" width="150px">Max Impact</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{maxImpact}</TableCell>
                                    </TableRow>
                                    <TableRow key={2}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Max Consequence</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{maxConsequence}</TableCell>
                                    </TableRow>
                                    <TableRow key={3}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }} >HGVS Consequence</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>{HGVSp}</TableCell>
                                    </TableRow>
                                    <TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Gene</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{geneSymbol}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item style={{minWidth:"370px", flexGrow: 0 }}>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    <TableRow key={5}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Allele Count</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{alleleCount}</TableCell>
                                    </TableRow>
                                    <TableRow key={6}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Allele Number</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{alleleNum}</TableCell>
                                    </TableRow>
                                    <TableRow key={7}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Allele Frequency</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{formatScientific(alleleFreq)}</TableCell>
                                    </TableRow>
                                    <TableRow key={8} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={{ width: 150 }}>Num Homozygotes</TableCell>
                                        <TableCell align="left" style={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>{nHomozygotes}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
};
export default VariantInfo;
