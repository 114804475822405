import {useEffect, useMemo, useRef} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {formatScientific} from '../../utils/utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './tables.css'

const PopulationTable = (props) => {
    const [chrom, pos, alt, ref] = props['varId'].split("-")
    const {data} = props;
    const gridRef = useRef();
    data.sort((a, b) => a.population < b.population ? -1 : 1)
    const columnDefs = [
        {
            field: "population", headerName: "Ancestry",
            minWidth: 120
        },
        {
            field: "alleleCount",
            minWidth: 90
        },
        {
            field: "alleleNum",
        },
        {
            field: "nHomozygotes",
            minWidth: 110
        },
        {
            field: "alleleFreq", headerName: "Allele Frequency",
            valueFormatter: (params) => formatScientific(params.value)
        }
    ]
    
    if (chrom === 'chrX') {columnDefs.push({field: "nHemiAlt", headerName: "N Hemizygotes"})}

    useEffect(() => {
        const gridApi = gridRef.current?.api;

        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }

    }, [data]);

    const defaultColDefs = useMemo(() => {
        return {
            flex: 1,
            minWidth: 100,
            resizable: true,
            sortable: true,
        }
    }, [])

    return (
        <div className="ag-theme-alpine" style={{
            height: "75vh",
            textAlign: "left"
        }
        }>

            <AgGridReact
                domLayout='autoHeight'
                ref={gridRef}
                rowData={data}
                columnDefs={columnDefs}
                defaultColDef={defaultColDefs}
                rowBuffer={35}
                enableRangeSelection
                suppressDragLeaveHidesColumns
            >
            </AgGridReact>
        </div>
    );
};


export default PopulationTable;
