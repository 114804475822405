import {Link} from "@mui/material";
import {formatScientific} from "../../utils/utils";


function matchParamsMaxImpact(params, maxImpact) {
    return params.data.maxImpact === maxImpact;
}

const cellClassRulesVEP = {
    // 'LOW', 'MODERATE', 'HIGH', 'LOWEST', 'VEP_failed'
    'vep-lowest': params => matchParamsMaxImpact(params, "LOWEST"),
    'vep-low': params => matchParamsMaxImpact(params, "LOW"),
    'vep-moderate': params => matchParamsMaxImpact(params, "MODERATE"),
    'vep-high': params => matchParamsMaxImpact(params, "HIGH"),
    'vep-failed': params => matchParamsMaxImpact(params, "VEP_failed"),
};

const columnDefs = [
    {
        headerName: "Variant ID",
        field: "varId",
        minWidth: 200,
        valueGetter: (params) => {
            const d = params.data;
            return `${d.Chrom}-${d.Pos}-${d.Ref}-${d.Alt}`;
        },
        cellRenderer: ((props) => {
            const variantId = props.value;
            return (
                <Link href={`/variant/${variantId}`} target="_blank" rel="noopener noreferrer">
                    {variantId}
                </Link>
            );
        })
    },
    {
        headerName: "rsID", field: "rsID",
        minWidth: 130,
    },
    {
        headerName: "Gene",  field: "geneSymbol"
    },
    {
        headerName: "Max Impact (VEP)", field: "maxImpact",
        hide: true, filter: "agSetColumnFilter"
    },
    {
        headerName: "VEP Annotation", field: "maxConsequence",
        minWidth: 200,
        cellClass: "vep",
        cellClassRules: cellClassRulesVEP,
        floatingFilter: true,
        filter: "agMultiColumnFilter",
        filterParams: {
            filters: [
                {
                    filter: "agTextColumnFilter"
                },
                {
                    filter: "agSetColumnFilter",
                    applyMiniFilterWhileTyping: true,
                },
            ],
        }
    },
    {
        headerName: "HGVS Consequence", field: "HGVSp",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        minWidth: 100
    },
    {
        headerName: "Allele Count", field: "alleleCount",
        type: 'numericColumn',
        filter: "agNumberColumnFilter",
        minWidth: 100
    },
    {
        headerName: "Allele Number", field: "alleleNum",
        type: 'numericColumn',
        filter: "agNumberColumnFilter",
        minWidth: 100
    },
    {
        headerName: "Allele Frequency", field: "alleleFreq",
        type: 'numericColumn',
        filter: "agNumberColumnFilter",
        valueFormatter: (params) => formatScientific(params.value),
        minWidth: 100

    },
    {
        headerName: "Number of Homozygotes", field: "nHomozygotes",
        type: 'numericColumn',
        filter: "agNumberColumnFilter",
        minWidth: 110
    },
];

export default columnDefs;