import Card from "@mui/material/Card";
import InfoCardContent from "./InfoCardContent";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Button, Checkbox, FormControlLabel, FormGroup, Radio} from "@mui/material";
import {capitalise} from "../../utils/utils";
import {AgGridReact} from "ag-grid-react";
import {useMemo, useRef, useState} from "react";
import './tables.css'

function VariantAggregateTable(props) {
    const {customColDefs, rowData, pageType, pageTypeValue} = props;
    const gridRef = useRef();
    const vepFilterOptions = [
        "lowest", "low", "moderate", "high"
    ];
    const vepFilterColors = ["green", "#7fd97f", "#c2c241", "darkred", "cornflowerblue"];
    const [selectedVepFilterOptions, setSelectedVepFilterOptions] = useState(vepFilterOptions);
    const defaultColDefs = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true
        }
    }, []);

    const applyFilters = (newFilters) => {
        const impactFilter = gridRef.current.api.getFilterInstance('maxImpact');
        const allSelectedVep = vepFilterOptions.length === newFilters.length;
        if (allSelectedVep) {
            impactFilter.setModel(null);
        } else {
            impactFilter.setModel({values: newFilters.map(opt => opt.toUpperCase())})
        }
        gridRef.current.api.onFilterChanged();
    }

    const handleVEPFilter = (event) => {
        // 'LOW', 'MODERATE', 'HIGH', 'LOWEST', 'VEP_failed'
        const checked = event.target.checked;
        const value = event.target.value;
        let newFilters;
        if (checked) {
            newFilters = selectedVepFilterOptions.concat(value);
        } else {
            newFilters = selectedVepFilterOptions.filter((opt) => opt !== value);
        }
        setSelectedVepFilterOptions(newFilters);
        applyFilters(newFilters)
    }

    const handleVEPFilterRadio = (event) => {
        const value = event.target.value;
        const newFilters = [value];
        setSelectedVepFilterOptions(newFilters);
        applyFilters(newFilters);
    }

    return (
        <div className="ag-theme-alpine" style={{height: "75vh", width: "90%", margin: "0 auto", textAlign: "left"}}>
            <Card>
                <InfoCardContent pageType={pageType} pageTypeValue={pageTypeValue}/>
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>VEP Impact:</Typography>
                    <FormGroup row sx={{width: "100%"}}>
                        {vepFilterOptions.map((opt, i) => (
                            <FormControlLabel key={opt}
                                              sx={{border: `1px solid ${vepFilterColors[i]}`,
                                                   borderRadius: 1,
                                                   borderOpacity: 0.6}}
                                              label={   <span>{capitalise(opt)}
                                                            <Radio style={{color: vepFilterColors[i]}}
                                                                   checked={selectedVepFilterOptions.length === 1 && selectedVepFilterOptions[0] === opt}
                                                                   onChange={handleVEPFilterRadio}
                                                                   value={opt}/>
                                                        </span>
                                              }
                                              control={<Checkbox style={{color: vepFilterColors[i]}}
                                                                 checked={selectedVepFilterOptions.indexOf(opt) > -1}
                                                                 value={opt}
                                                                 onChange={handleVEPFilter}/>}/>))}
                        <Button onClick={() => {
                                    const newFilters = vepFilterOptions.slice();
                                    setSelectedVepFilterOptions(newFilters);
                                    applyFilters(newFilters);
                                }}
                                variant="outlined"
                                sx={{p: 0}}>All</Button>
                    </FormGroup>
                </CardContent>
            </Card>
            <br/>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={customColDefs}
                defaultColDef={defaultColDefs}
                pagination={true}
                rowBuffer={35}
                enableRangeSelection
                suppressDragLeaveHidesColumns
                sideBar={{
                    toolPanels: [
                        {
                            id: "columns",
                            labelDefault: "Columns",
                            labelKey: "columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel",
                            toolPanelParams: {
                                suppressRowGroups: true,
                                suppressValues: true,
                                suppressPivots: true,
                                suppressPivotMode: true,
                            }
                        }
                    ]
                }}>
            </AgGridReact>
        </div>);}

export default VariantAggregateTable;
